import { defineNuxtPlugin } from '#imports'
import * as Sentry from '@sentry/browser'
import type { FetchOptions } from 'ofetch'
import { getAuthService } from '../apps/auth/service'
import { logger } from '../utility/logger'

const isDev = process.env.NODE_ENV !== 'production'

// Extend FetchOptions to include startTimestamp
interface TimestampedFetchOptions extends FetchOptions {
  startTimestamp?: number
}

function handleDataLayer(data: any, context: any) {
  if (data && data.dataLayer && context.$gtm) {
    const key = process.server ? '$gtmServer' : '$gtm'
    data.dataLayer.forEach((d: any) => context[key].push(d))
  }
}

export default defineNuxtPlugin((context) => {
  globalThis.$fetch = $fetch.create({
    timeout: 30000,
    baseURL: process.client
      ? ''
      : process.env.NUXT_AXIOS_BASE_URL || 'http://varnish_plus:8100',
    onRequest(ctx) {
      const { request, options } = ctx
      const url = typeof request === 'string' ? request : request.url
      // remove baseURL for api requests
      if (url.startsWith('/api/') || url.startsWith('/__')) {
        options['baseURL'] = undefined
      }
      if (url.startsWith('/__')) {
        // proxy the user-agent to the internal error pages
        const uaDevice = context.nuxt2Context.req.headers['x-ua-device']
        options.headers = {
          ...options.headers,
          'x-ua-device': uaDevice,
        }
      }
      if (isDev || process.env.LOG_REQUESTS) {
        console.log('fetch request: ', url)
      }

      // Set startTimestamp on options
      (options as TimestampedFetchOptions).startTimestamp = Date.now() / 1000
    },
    onResponse({ request, response, options }) {
      if (isDev) {
        const url = typeof request === 'string' ? request : request.url
        logger('fetch request: ', url)
      }
      handleDataLayer(response._data, context)

      // Use startTimestamp from options
      const startTimestamp =
        (options as TimestampedFetchOptions).startTimestamp || Date.now() / 1000
      const endTimestamp = Date.now() / 1000
      const duration = endTimestamp - startTimestamp

      Sentry.addBreadcrumb(
        {
          category: 'fetch',
          data: {
            method: options.method || 'GET',
            url: typeof request === 'string' ? request : request.url,
            status_code: response.status,
            duration,
          },
          type: 'http',
        },
        {
          input: options,
          response,
          startTimestamp,
          endTimestamp,
        },
      )
    },
    async onResponseError({ request, response, options }) {
      if (process.client) {
        const isAlfredRequest =
          request instanceof Request && request.headers.get('X-Alfred-Version')
        const isUnauthorized = response.status === 401

        if (isAlfredRequest && isUnauthorized) {
          const authMachine = await getAuthService()
          const isLoggedIn = authMachine.state.value === 'loggedIn'

          if (isLoggedIn) {
            authMachine.send('LOG_OUT')
          }
        }
      }
      handleDataLayer(response._data, context)

      // Use startTimestamp from options
      const startTimestamp =
        (options as TimestampedFetchOptions).startTimestamp || Date.now() / 1000
      const endTimestamp = Date.now() / 1000
      const duration = endTimestamp - startTimestamp

      Sentry.addBreadcrumb(
        {
          category: 'ofetch',
          data: {
            method: options.method || 'GET',
            url: typeof request === 'string' ? request : request.url,
            status_code: response.status,
            duration,
          },
          type: 'http',
        },
        {
          input: options,
          response,
          startTimestamp,
          endTimestamp,
        },
      )
    },
  })
})
