import { defineStore } from 'pinia'
import { ref } from 'vue'
import { RecentCampsiteDataRepositoryFetch } from '~/apps/recent-campsites/RecentCampsiteDataRepositoryFetch'
import { RecentCampsiteRepositoryLocalStorage } from '~/apps/recent-campsites/RecentCampsiteRepositoryLocalStorage'
import { RecentCampsiteDetails } from '~/apps/recent-campsites/types'

export const useRecentCampsitesStore = defineStore('recentCampsites', () => {
  const recentCampsiteRepository = new RecentCampsiteRepositoryLocalStorage()
  const campsiteDataRepository = new RecentCampsiteDataRepositoryFetch()
  const recentCampsites = ref<RecentCampsiteDetails[]>([])

  async function addCampsite(id: string) {
    if (recentCampsites.value[0]?.id === id) {
      recentCampsites.value[0].visitDate = new Date().toISOString()
      return
    }
    const campsite = await campsiteDataRepository.getById(id, 'en-gb')
    if (campsite) {
      const existingIndex = recentCampsites.value.findIndex(
        (c) => c.id === campsite.id,
      )
      if (existingIndex !== -1) {
        recentCampsites.value.splice(existingIndex, 1)
      }
      recentCampsites.value.unshift({
        ...campsite,
        visitDate: new Date().toISOString(),
      })
    }
    recentCampsiteRepository.add(id)
  }

  async function refreshCampsites(langCode = 'en-gb', limit = 6) {
    const recentCampsiteIds = recentCampsiteRepository.getAll(langCode, limit)
    const campsitesData = await Promise.all(
      recentCampsiteIds.map(({ id }) => campsiteDataRepository.getById(id, langCode).catch(() => null)),
    )
    recentCampsites.value = campsitesData.filter((campsite): campsite is RecentCampsiteDetails => !!campsite)
      .map((campsite, index) => ({
        ...campsite,
        visitDate: recentCampsiteIds[index].visitDate,
      }))
  }

  return {
    recentCampsites,
    addCampsite,
    refreshCampsites,
  }
})