import { Plugin } from '@nuxt/types'

const isDev = process.env.NODE_ENV !== 'production'

const dataLayerAxios: Plugin = (context) => {
  // note runs on both server and client
  context.$axios.interceptors.response.use((res) => {
    if (isDev) console.log('axios request: ', res.config.url)
    if (res.data && res.data.dataLayer && context.$gtm) {
      const key = process.server ? '$gtmServer' : '$gtm'
      res.data.dataLayer.forEach((d: any) => context[key].push(d))
    }
    return res
  })
}
export default dataLayerAxios
