import { getAuthService } from '../apps/auth/service'
import { Plugin } from '@nuxt/types'
import { AxiosError } from 'axios'

const authInterceptorAxiosClient: Plugin = ({ $axios }) => {
  $axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const isAlfredRequest = error.response?.config.headers['X-Alfred-Version']
      const isUnauthorized = error.response?.status === 401

      if (isAlfredRequest && isUnauthorized) {
        const authMachine = await getAuthService()
        const isLoggedIn = authMachine.state.value === 'loggedIn'

        if (isLoggedIn) {
          authMachine.send('LOG_OUT')
        }
      }

      throw error
    },
  )
}

export default authInterceptorAxiosClient
