import { defineStore } from 'pinia';
import { getFavouritesMachine } from '~~/apps/favourites/favourites.machine';
export var useFavouritesStore = defineStore('favourites', () => {
  var router = useRouter();
  var route = router.currentRoute;
  var favouritesMachine = getFavouritesMachine(route.params.lang || 'en-gb');

  // Machine returned is a singleton so may have already loaded it's context
  // in which case it won't trigger below onChange, so set ref as initial context.
  var favourites = ref(favouritesMachine.state.context.favourites.reduce((obj, item) => Object.assign(obj, {
    [item.id]: item.added
  }), {}));
  favouritesMachine.onChange(_ref => {
    var {
      favourites: loadedFavourites
    } = _ref;
    favourites.value = loadedFavourites.reduce((obj, item) => Object.assign(obj, {
      [item.id]: item.added
    }), {});
  });
  return {
    favourites
  };
});